import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import Flag from 'react-flagkit';
import { getCookie } from 'cookies-next';
import { getGlossaryLanguage } from '../../util/DataUtil';

// Country options
const countries = [
    { value: '', label: 'Select ...', code: '' },
    { value: 'US', label: 'United States', code: 'US' },
    { value: 'GB', label: 'United Kingdom', code: 'GB' },
    { value: 'AL', label: 'Albania', code: 'AL' },
    { value: 'AD', label: 'Andorra', code: 'AD' },
    { value: 'AM', label: 'Armenia', code: 'AM' },
    { value: 'AU', label: 'Australia', code: 'AU' },
    { value: 'AT', label: 'Austria', code: 'AT' },
    { value: 'BY', label: 'Belarus', code: 'BY' },
    { value: 'BE', label: 'Belgium', code: 'BE' },
    { value: 'BA', label: 'Bosnia and Herzegovina', code: 'BA' },
    { value: 'BR', label: 'Brussels', code: 'BE' },
    { value: 'BG', label: 'Bulgaria', code: 'BG' },
    { value: 'CA', label: 'Canada', code: 'CA' },
    { value: 'CN', label: 'China', code: 'CN' },
    { value: 'HR', label: 'Croatia', code: 'HR' },
    { value: 'CY', label: 'Cyprus', code: 'CY' },
    { value: 'CZ', label: 'Czech Republic', code: 'CZ' },
    { value: 'DK', label: 'Denmark', code: 'DK' },
    { value: 'EE', label: 'Estonia', code: 'EE' },
    { value: 'FI', label: 'Finland', code: 'FI' },
    { value: 'FR', label: 'France', code: 'FR' },
    { value: 'GE', label: 'Georgia', code: 'GE' },
    { value: 'DE', label: 'Germany', code: 'DE' },
    { value: 'GR', label: 'Greece', code: 'GR' },
    { value: 'HK', label: 'Hong Kong', code: 'HK' },
    { value: 'HU', label: 'Hungary', code: 'HU' },
    { value: 'IS', label: 'Iceland', code: 'IS' },
    { value: 'ID', label: 'Indonesia', code: 'ID' },
    { value: 'IE', label: 'Ireland', code: 'IE' },
    { value: 'IT', label: 'Italy', code: 'IT' },
    { value: 'JP', label: 'Japan', code: 'JP' },
    { value: 'LV', label: 'Latvia', code: 'LV' },
    { value: 'LT', label: 'Lithuania', code: 'LT' },
    { value: 'LU', label: 'Luxembourg', code: 'LU' },
    { value: 'MK', label: 'North Macedonia', code: 'MK' },
    { value: 'MY', label: 'Malaysia', code: 'MY' },
    { value: 'MX', label: 'Mexico', code: 'MX' },
    { value: 'MC', label: 'Monaco', code: 'MC' },
    { value: 'NZ', label: 'New Zealand', code: 'NZ' },
    { value: 'KP', label: 'North Korea', code: 'KP' },
    { value: 'NO', label: 'Norway', code: 'NO' },
    { value: 'OM', label: 'Other', code: 'OM' },
    { value: 'PW', label: 'Palau', code: 'PW' },
    { value: 'PG', label: 'Papua New Guinea', code: 'PG' },
    { value: 'PH', label: 'Philippines', code: 'PH' },
    { value: 'PL', label: 'Poland', code: 'PL' },
    { value: 'PT', label: 'Portugal', code: 'PT' },
    { value: 'RO', label: 'Romania', code: 'RO' },
    { value: 'RU', label: 'Russia', code: 'RU' },
    { value: 'SM', label: 'San Marino', code: 'SM' },
    { value: 'GB-SCT', label: 'Scotland', code: 'GB-SCT' },
    { value: 'SG', label: 'Singapore', code: 'SG' },
    { value: 'SK', label: 'Slovakia', code: 'SK' },
    { value: 'SI', label: 'Slovenia', code: 'SI' },
    { value: 'WS', label: 'Samoa', code: 'WS' },
    { value: 'KR', label: 'South Korea', code: 'KR' },
    { value: 'ES', label: 'Spain', code: 'ES' },
    { value: 'SE', label: 'Sweden', code: 'SE' },
    { value: 'CH', label: 'Switzerland', code: 'CH' },
    { value: 'TW', label: 'Taiwan', code: 'TW' },
    { value: 'TH', label: 'Thailand', code: 'TH' },
    { value: 'NL', label: 'Netherlands', code: 'NL' },
    { value: 'TR', label: 'Turkey', code: 'TR' },
    { value: 'UA', label: 'Ukraine', code: 'UA' },
    { value: 'VN', label: 'Vietnam', code: 'VN' },
    { value: 'GB-WLS', label: 'Wales', code: 'GB-WLS' },
    { value: 'EU', label: 'Western Europe (Other)', code: 'EU' }
];

// US State options
const states = [
    { value: 'AK', label: 'AK' },
    { value: 'AL', label: 'AL' },
    { value: 'AZ', label: 'AZ' },
    { value: 'AR', label: 'AR' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DE', label: 'DE' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'HI', label: 'HI' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'IA', label: 'IA' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'ME', label: 'ME' },
    { value: 'MD', label: 'MD' },
    { value: 'MA', label: 'MA' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MS', label: 'MS' },
    { value: 'MO', label: 'MO' },
    { value: 'MT', label: 'MT' },
    { value: 'NE', label: 'NE' },
    { value: 'NV', label: 'NV' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NY', label: 'NY' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VT', label: 'VT' },
    { value: 'VA', label: 'VA' },
    { value: 'WA', label: 'WA' },
    { value: 'WV', label: 'WV' },
    { value: 'WI', label: 'WI' },
    { value: 'WY', label: 'WY' }
];


const formatOptionLabel = ({ label, code }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {
            code ?
            <Flag country={code} style={{ marginRight: 10, boxSizing: 'content-box' }} /> : null
        }
        {label}
    </div>
);

const CaretDownIcon = () => {
    return <img alt={'arrow-down'} src={'/images/arrow-down.png'} />
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>
    );
};

const CustomDropdown = ({
    setCustomDropdownValue,
    options,
    fieldId,
    label,
    formId,
    glossary = '',
    lang = ''
}) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [requiredField, setRequiredField] = useState(false);
    const [form, setForm] = useState(null);
    const [selectedState, setSelectedState] = useState(null);

    const handleChange = useCallback((fieldId, selectedOption) => {
        if (fieldId === 'Company_HQ_State__c') {
            setSelectedState(selectedOption);
        }else {
            setSelectedCountry(selectedOption);
        }

        setRequiredField(!selectedOption?.value);
        const originalDropDown = document.getElementById(`custom-selector-${fieldId}`);
        const firstChildDiv = originalDropDown?.querySelector('div');
        if(!selectedOption?.value && firstChildDiv){
            firstChildDiv.classList.add('removeError')
        }else {
            firstChildDiv?.classList?.remove('removeError')
        }
        const originalSelect = document.getElementById(fieldId);
        if (originalSelect) {
            // originalSelect.value = fieldId === 'Company_HQ_Country__c'
            // ? selectedOption?.label?.toUpperCase()
            // : (fieldId.includes('Country') ? selectedOption?.label : selectedOption?.value)

            const event = new Event('change', { bubbles: true });
            originalSelect.dispatchEvent(event);

            if (window.MktoForms2) {
                const form = window.MktoForms2.getForm(formId);

                if (form) {
                    form.setValues({
                        [fieldId]: fieldId === 'Company_HQ_Country__c'
                        ? selectedOption?.label?.toUpperCase()
                        : (fieldId.includes('Country') ? selectedOption?.label : selectedOption?.value)
                    });

                    const formValues = form.vals();
                    setForm(formValues);

                    console.log('Current form values:', formValues);
                    
                    let oldMktoSelectField = document.getElementById('LblCompany_HQ_State__c');
                    let oldFormMktoSelectField = document.getElementById('Company_HQ_State__c');
                    if(oldMktoSelectField){
                        let parentOfOld = oldMktoSelectField.closest('.mktoFormCol');
                        parentOfOld.style.display = 'none';
                    } else if(oldFormMktoSelectField){
                        let parentOfOldForm = oldFormMktoSelectField.closest('.mktoFormCol');
                        parentOfOldForm.style.display = 'none';
                    }

                } else {
                    console.error('Cannot find Marketo form.');f
                }
            } else {
                console.error('MktoForms2 is not initialized.');
            }
        }

        setCustomDropdownValue({
            name: fieldId,
            value: fieldId === 'Company_HQ_Country__c'
            ? selectedOption?.label?.toUpperCase()
            : (fieldId.includes('Country') ? selectedOption?.label : selectedOption?.value)
        });
    }, [setSelectedCountry, setRequiredField, setCustomDropdownValue, formId]);

    
    const translateLabel = (text) => {
        if (lang !== 'en' && glossary && glossary.length > 0) {
            const translatedItem = glossary.find(item => item['en'].toLowerCase() === text.toLowerCase());
            return translatedItem ? translatedItem[getGlossaryLanguage(lang)] : text;
        }
        return text;
    };
    
    useEffect(() => {
        const marketoVals = getCookie('marketovals');

        if (marketoVals) {
          const parsedVals = JSON.parse(marketoVals);

            if (parsedVals[fieldId]) {
                if (fieldId.includes('Country') && fieldId !== 'Company_HQ_State__c') {
                    const defaultCountry = countries.find(country => country.label === parsedVals[fieldId]);
                    if (defaultCountry) {
                        setSelectedCountry(defaultCountry);
                    }
                } else if (fieldId === 'Company_HQ_State__c') {
                    const defaultState = states.find(state => state.label === parsedVals[fieldId]);
                    if (defaultState) {
                        setSelectedState(defaultState);
                    } 
                } else {
                    const defaultValue = options.find(option => option.value === parsedVals[fieldId]);
                    if (defaultValue) {
                        setSelectedCountry(defaultValue);
                    }
                }
            }
        }

        return () => {
            setSelectedCountry(null)
        }
      }, [fieldId]);


      useEffect(() => {
        const marketoVals = getCookie('marketovals');
        let form;
    
        if (window.MktoForms2) {
            form = window.MktoForms2.getForm(formId);
        }
    
        if (form && marketoVals) {
            setValuesInForm(form, JSON.parse(marketoVals));
        }
    }, [formId]);

    useEffect(() => {
        const elements = document.querySelectorAll('#custom-selector-Racks_Required');
        
        if (elements.length > 1) {
          elements[1].style.display = 'none';
        }
      }, []);

    
    const setValuesInForm = (form, marketoVals) => {
        Object.keys(marketoVals).forEach((fieldId) => {
            if (marketoVals[fieldId]) {
                if (fieldId.includes('Country') && fieldId !== 'Company_HQ_State__c') {
                    const defaultCountry = countries.find(country => country.label === marketoVals[fieldId]);
                    if (defaultCountry) {
                        form.setValues({
                            [fieldId]: defaultCountry.label
                        });
                    }
                }

                else if (fieldId === 'Company_HQ_State__c') {
                    const defaultState = states.find(state => state.label === marketoVals[fieldId]);
                    if (defaultState) {
                        form.setValues({
                            [fieldId]: defaultState.label
                        });
                    }
                }
                else {
                    const defaultValue = options.find(option => option.value === marketoVals[fieldId]);
                    if (defaultValue) {
                        form.setValues({
                            [fieldId]: defaultValue.value
                        });
                    }
                }
            }
        });
    };
    
    
    const updatedCountries = [
        { ...countries[0], label: options[0]?.label || 'Select ...' },
        ...countries.slice(1),
    ];

    return ( options?.length > 0 ? <>
            <div id={'custom-dropdown-wrapper'} className={requiredField ? "mktoFormCol addHover removeError" : "mktoFormCol addHover"} style={{ marginBottom: '10px' }}>
                <div className="mktoOffset" style={{ width: '10px' }} />
                <div className="mktoFieldWrap mktoRequiredField">
                    <label htmlFor="custom-dropdown" id={fieldId} className="mktoLabel mktoHasWidth active" style={{width: '100px', zIndex: '1'}}>
                        <div className="mktoAsterix">*</div>
                        {label}
                    </label>
                    <div className="mktoGutter mktoHasWidth" style={{width: '10px'}}></div>
                    <Select
                        id="custom-dropdown"
                        name={fieldId}
                        // options={countries}
                        // options={options}
                        options={fieldId.includes('Country') ? updatedCountries : options}
                        formatOptionLabel={formatOptionLabel}
                        placeholder={options[0]?.label}
                        onChange={(selectedOption) => handleChange(fieldId, selectedOption)}
                        className={`mktoField mktoHasWidth mktoRequired ${requiredField ? 'mktoInvalid' : 'mktoValid'}`}
                        components={{DropdownIndicator}}
                        value={selectedCountry}
                        // menuIsOpen
                        styles={{
                            control: (base) => ({
                                ...base,
                                boxShadow: 'none',
                                border: 0
                            }),
                        }}
                    />
                    {
                        // requiredField &&
                        // <div className="mktoError removeError">
                        //     <div className="mktoErrorArrowWrap">
                        //         <div className="mktoErrorArrow"></div>
                        //     </div>
                        //     <div id="ValidMsgCompany_HQ_Country__c" role="alert" tabIndex="-1" className="mktoErrorMsg newErrorMsg">This field is required.</div>
                        // </div>
                    }
                    <span id="InstructCompanyHQCountry" tabIndex="-1" className="mktoInstruction"></span>
                    <div className="mktoClear"></div>
                </div>
                <div className="mktoClear"></div>
            </div>
            {form?.Company_HQ_Country__c === 'UNITED STATES' && fieldId === 'Company_HQ_Country__c' ? (
                <div id={'custom-dropdown-wrapper'} className={requiredField ? "mktoFormCol addHover removeError" : "mktoFormCol addHover"} style={{ marginBottom: '10px' }}>
                    <div className="mktoOffset" style={{ width: '10px' }} />
                    <div className="mktoFieldWrap">
                        <label htmlFor="company-hq-state" id="LblCompanyHQState" className="mktoLabel mktoHasWidth" style={{ width: '100px', zIndex: '9' }}>
                            <div className="mktoAsterix">*</div>  {translateLabel("Customer HQ State")}*
                        </label>
                        <div className="mktoGutter mktoHasWidth" style={{ width: '10px' }}></div>
                        <Select
                            id="custom-dropdown"
                            name="Company_HQ_State__c"
                            options={states}
                            placeholder="Select a state..."
                            className="mktoField mktoHasWidth mktoValid"
                            onChange={(selectedOption) => handleChange('Company_HQ_State__c', selectedOption)}
                            value={selectedState}
                            // menuIsOpen
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    boxShadow: 'none',
                                    border: 0
                                }),
                            }}
                        />
                        <span id="InstructCompany_HQ_Country__c" tabIndex="-1" className="mktoInstruction"></span>
                        <div className="mktoClear"></div>
                    </div>
                    <div className="mktoClear"></div>
                </div>
            ) : null}

        </> : null
    );
};

export default memo(CustomDropdown);