import { getGlossaryLanguage } from "./DataUtil";

let host = 'https://087-YZJ-646.mktorest.com';

export function handleFocus(fields, glossary, lang){
    const elementIds = [
        "#KR_Partner_Solution_Required__c",
        "#Power_Range__c",
        "#Racks_Required__c",
        "#Cloud_Service_Connectivity__c",
        "#Preferred_location__c",
        "#Renewal__c",
        "#KR_Partner_Type__c",
        "#Company_HQ_Country__c",
        "#KR_Partner_Lead_Primary_Contact__c",
        "#Industry",
        ".mktoHtmlText",
        "#Country",
        "#Status_Comments__c"
    ];
    
    elementIds.forEach((id) => {
        const element = document.querySelector(id);
        if (element) {
            const elementsToTranslate = id === "#Country"
                ? [element.options?.[0]]
                : id === ".mktoHtmlText"
                    ? [element]
                    : element.options || [element];
    
            translateElements(elementsToTranslate, glossary, lang);
        }
    });
    
    if(fields){
        for(let items of fields){
            for (const field of items) {                
                field.addEventListener('focus', function (event) {
                    const mktoError = document.querySelector('.mktoInvalid');
                    const mktoErrorMsg = document.querySelectorAll('.mktoErrorMsg');
                    const mktoRequiredLabel = document.querySelector('.mktoRequiredLabel');                    

                    if (mktoError) {
                        translateElements(mktoErrorMsg, glossary, lang);
                        // mktoError.classList.add('removeError');
                    }

                    // if(mktoRequiredLabel){
                    //     mktoRequiredLabel.style.display = 'none';
                    // }
                    
                    event.stopPropagation();
                    activeClass(event, fields);                   
                });
                
                field.addEventListener('keydown', function (event) {
                    event.stopPropagation();

                    if (!event.shiftKey) {
                        activeClass(event, fields);

                        const element = document.getElementById('Email');
                        const count = element?.value?.length;
                
                        if (count > 0) {
                            const mktoError = document.querySelector('.mktoError');

                            if (mktoError) {
                                mktoError.style.display = 'none';
                            }
                        }
                    }
                });
                
                field.addEventListener('focusout', function (event) {
                    event.stopPropagation();
                    removeActiveClass(fields);
                    const mktoError = document.querySelector('.mktoInvalid');

                    let element = event.target;
                    const parentElement = element.closest('.mktoFormCol');
                    const mktoValid = document.querySelector('.mktoValid');

                    if ((event.target?.value?.length === 0 && event.target?.classList.contains('mktoRequired')) || (mktoError && field.id === 'custom-dropdown')) {
                        event.target.classList.add('error');
                        const mktoError = document.querySelector('.mktoInvalid');
                        
                        if (mktoError && mktoError && field.id !== 'custom-dropdown') {
                            // mktoError.classList.add('removeError');
                            parentElement.classList.add('removeError');
                        }
                        if (mktoValid) {
                            mktoValid.classList.remove('mktoFilled');
                            parentElement.classList.remove('mktoFilled');
                        }
                
                    } else if (event.target.type === 'email') {
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailPattern.test(event.target.value.trim())) {
                            event.target.classList.add('error');
                            parentElement.classList.add('removeError');
                            parentElement.classList.remove('mktoFilled');
                        } else {
                            event.target.classList.remove('error');
                            parentElement.classList.remove('removeError');
                            parentElement.classList.add('mktoFilled');
                        }
                
                    } else if (event.target.type === 'tel') {
                        const phonePattern = /^\+?[0-9\s\-()]+$/;
                        if (!phonePattern.test(event.target.value.trim())) {
                            event.target.classList.add('error');
                            parentElement.classList.add('removeError');
                            parentElement.classList.remove('mktoFilled');
                        } else {
                            event.target.classList.remove('error');
                            parentElement.classList.remove('removeError');
                            parentElement.classList.add('mktoFilled');
                        }
                
                    } else {
                        event.target.classList.remove('error');
                        parentElement.classList.remove('removeError');
                
                        if (mktoValid && event.target.type != 'checkbox') {
                            mktoValid.classList.add('mktoFilled');
                            parentElement.classList.add('mktoFilled');
                        } else {
                            mktoValid.classList.remove('mktoFilled');
                            parentElement.classList.remove('mktoFilled');
                        }
                    }
                });
                             
            }
        }
    }
}

export function removeActiveClass(fields) {
    const button = document.querySelector(".marketo-form button");

    if (fields) {
        for (const items of fields) {
            for (const item of items) {
                const elem = item.parentElement.firstChild;

                if (item?.value?.length === 0 && button !== document.activeElement && item?.type !== 'select-one') {
                    elem.classList.remove('active');
                }
            }
        }
    }
}

export function activeClass(event, fields){
    removeActiveClass(fields);

    let prevSibling = event.target.previousElementSibling?.previousElementSibling;

    if(prevSibling?.nodeName === "LABEL"){
        prevSibling.classList.add('active');
    }
}

export function autoPopulateAddActive(fields){
    for(let items of fields){
        for (const field of items) {
            if(field.labels !== null){
                field.labels?.[0]?.classList.add('active')
                let prevSibling = field.previousElementSibling?.previousElementSibling;

                if(prevSibling?.nodeName === "LABEL"){
                    prevSibling.classList.add('active');
                }
            }
        }
    }
}

export function translateElements(data, glossary, lang) {
    if (!glossary || glossary.length === 0) {
        return;
    }

    function normalizeAndCompare(str1, str2) { 
        // Replace various whitespace characters with regular spaces
        const normalizedStr1 = str1?.replace(/\s+/g, ' ').trim().toLowerCase();
        const normalizedStr2 = str2?.replace(/\s+/g, ' ').trim().toLowerCase();
        
        // Handle special characters and punctuation
        const cleanStr1 = normalizedStr1?.replace(/[^\w\s]/g, '');
        const cleanStr2 = normalizedStr2?.replace(/[^\w\s]/g, '');
    
        // Use locale-specific comparison for better Unicode support
        return cleanStr1.localeCompare(cleanStr2) === 0;
    }

    for (let item of data) {        
        let innerText = item?.innerText;

        for (let word of glossary) {            
            if (normalizeAndCompare(word['en'], innerText)) {         
                item.innerHTML = word[getGlossaryLanguage(lang)];                
                break; // Exit the loop once a match is found
            }
        }
    }
}

export function translatePlaceholders(inputs, glossary, lang) {
    if (!glossary || glossary.length === 0) {
        return;
    }

    function normalizeAndCompare(str1, str2) { 
        const normalizedStr1 = str1?.replace(/\s+/g, ' ').trim().toLowerCase();
        const normalizedStr2 = str2?.replace(/\s+/g, ' ').trim().toLowerCase();
        const cleanStr1 = normalizedStr1?.replace(/[^\w\s]/g, '');
        const cleanStr2 = normalizedStr2?.replace(/[^\w\s]/g, '');
    
        return cleanStr1.localeCompare(cleanStr2) === 0;
    }

    inputs.forEach(input => {
        let placeholderText = input?.getAttribute('placeholder');

        for (let word of glossary) {            
            if (normalizeAndCompare(word['en'], placeholderText)) {         
                input.setAttribute('placeholder', word[getGlossaryLanguage(lang)]);
                break; 
            }
        }
    });
}


const validateForm = (fields) => {
    return Array.from(fields).every((field) => field.value?.trim() !== '');
};
var checkbox = false;

export function handleChange(formId, event) {
    const buttons = document.querySelectorAll("#mktoForm_" + formId + " button");
    const fields = document.querySelectorAll("#mktoForm_" + formId + " .mktoRequired");
    const fields2 = document.querySelectorAll("#mktoForm_" + formId + " .mktoRequiredField");

    const combinedFields = [...fields, ...fields2];

    if(event.target.type === 'checkbox'){
        if(event.target.value === 'no'){
            event.target.value = 'yes';
             checkbox = false;
        }else {
            event.target.value = 'no';
             checkbox = true;
        }
    }

    
    const isValid = validateForm(combinedFields);
    if(checkbox && isValid){
        buttons[0]?.classList.toggle('active', isValid);
    }else {
        buttons[0]?.classList.toggle('disable', false);
        buttons[0]?.classList.remove('active');
    }
}

export function enableMarketoButton(formId, event, checked){
    const buttons = document.querySelectorAll("#mktoForm_" + formId + " button");
    buttons[0]?.classList.toggle('enable', checked);
}